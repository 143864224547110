<template>
    <div>
        <div class="catBContainer mt-6">
            <template v-for="cat in categories">
                <div class="catButton" :key="cat" @click="changePage(cat)">
                    <p class="catButtonP" :id="cat">{{ cat }}</p>
                </div>
            </template>
        </div>
        <div class="bonC">
            <div class="img">
            <img  :src="require('/src/assets/carteCadeaux/carteCadeaux.png')">
            <div class="formuleName has-text-centered" @click="toBon()">
                <p class="textFormule">Bon cadeaux</p>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'FormuleButton',
  data() {
    return {
        categories: [
            "Portrait",
            "Mariage",
            "Corporate"
        ]
    }
  },
  methods: {
      changePage(cat) {
          this.$emit("change", cat)
      },
      toBon() {
          this.$emit("detailsFormule", "BON CADEAUX")
      }
  }
}
</script>

<style scoped>
.catBContainer{
    display: flex;
    justify-content: center;
}

.title {
    margin: 1rem;
    text-align: center;
    color: #F2FFFF;
    font-size: 2rem;
}

.title::first-letter {
    color: #780E10;
}

.catButton{
    width: 33vw;
    height: 50vh;
    border-right: #780E10 solid 5px;
    transition: all 0.5s;
}

.catButton:hover {
    width: 36%;
}

.catButton:last-of-type{
    border: none;
}

.catButtonP{
    text-align: center;
    padding-top:10rem;
    font-size: 2.75rem;
    font-family: 'Sofia Sans', sans-serif;
}

.formuleName {
    position: relative;
    top: -486.5px;
    width: 600px;
    height: 420px;
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}
.textFormule {
    position: relative;
    top: 35%;
    font-size: 5rem;
    font-family: 'Moon Dance', cursive;
}

.formuleName:hover {
    opacity: 0;
}

.img {
    width:600px
}

.bonC {
    text-align:center;
    margin-top:3rem;
    height:420px;
    display: flex;
    justify-content: center;
}


@media (max-width:550px) {
    .catBContainer {
        flex-wrap: wrap;
    }

    .bonC {
        width: fit-content;
        margin-left: 3.5rem;
    }

    .textFormule {
        font-size: 3rem;
        width: 100%;
    }

    .catButton {
        width: fit-content;
        border: none;
    }

    .catButton:hover {
        width: fit-content;
    }

    .catButton:first-of-type {
        border:none;
    }
    .catButton {
        border-top: #780E10 solid 5px;
    }

    .catButton:last-of-type {
        border-top: #780E10 solid 5px;
    }

    .catButtonP {
        width: 60vw;
    }

    .img {
        width: 70vw;
    }
    .formuleName {    
        width: 70vw;
        top: -40.5vh;
        height: 40.5vh;
    }
}
</style>