<template>
  <div>
  <h2 class="has-text-centered big-title">
    Qui suis-je ?
  </h2>
  <div class="content columns" id="contain">
    <div class="column is-half" id="text">
      <p id="Fisrt">Je découvre l'univers de la photographie à l'âge de mes 8 ans où j'ai eu mon tout premier appareil photo entre les mains. C'est là que commence mon aventure !</p>
      <br/>
      <br/>
      Je suis passée par plusieurs phases artistiques : la photographie, la couture, le dessin, l'écriture, la peinture, la danse... Tout s'entremêlait, se fusionnait... Puis au lycée, j'ai suivi un enseignement d'arts plastiques et de cinéma. C'est là que j'ai su que la photo, c'était en fait, une histoire d'amour. C'est à partir de ce moment que je me suis tournée vers la photographie de portrait, car je trouvais que c'était tellement puissant de faire transparaître dans un instanté une histoire, un univers, une émotion. Ça me fascine.
      <br/>
      <br/>
      <br/>
      J'ai appris en autodidacte et je fais des formations pro pour toujours plus enrichir mes compétences. Je fais également des études à côté. J'ai un BTS audiovisuel en option gestion de la production. Les deux branches sont assez complémentaires. La photographie est mon moyen de faire de l’art, mon moyen de m'exprimer, mon moyen de partager. J’ai toujours été attiré par ces aspects créatifs, relationnels et en constante évolution.
      <br/>
      <br/>
      <br/>
      J'ai de l'ambition et je ne tiens plus à perdre mon temps en laissant ce qui me fait douter, angoisser, prendre le dessus, m'empêchant d'être dans l'action. Dès lors, j'ai choisi de faire de ma passion mon métier. J'aime innover, me surpasser, je suis curieuse, optimiste et ce métier me rend heureuse. Je suis toujours à vouloir aller prendre les meilleurs clichés (hum oui, je suis perfectionniste aussi) et les graver dans le temps pour de meilleurs souvenirs, aux plus grands plaisirs de mes clients.
    </div>
    <div class="image">
      <img :src="require('/src/assets/photoM.jpg')" class="card-img">
      <div class="background"></div>
      <p class="img-text">Photographiée par @alix_charvin_photography</p>
    </div>
  </div>
    <Footer></Footer>
  </div>

</template>

<script>
import Footer from './Footer.vue'

export default {
  name: 'New',
  components: { Footer },
  data() {
    return {}
  }
}
</script>

<style scoped>
#text {
  padding: 2rem 1rem 1rem 0;
  text-align: justify;
}
#contain {
  margin-left: 6vw;
  padding: 2.5rem;
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.card-img {
  border-radius: 0.25rem;
  margin-left: 1.5rem;
  max-height: 100vh;
  position: relative;
  z-index: 1;
  min-width: 40vw;
}
.img-text {
  padding-left: 2.75rem;
  font-size: 0.75rem;
  position: relative;
  z-index: 1;

}
#Fisrt ::first-letter {
  color: red
}

.big-title {
  font-size: 5rem;
  font-family: 'Moon Dance', cursive;
  margin-top: 2rem;
}

.background {
  min-width: 50vw;
  height: inherit;
  position: absolute;
  top: -15px;
  left: 40px;
  background-color: #780E10;
  z-index: 0;
  border-radius: 0.25rem;
}

.image {
  width: min-content;
  height: 87vh;
}

@media (max-width:950px) {
    #text {
      width: 75vw;
    }

    .image {
      margin-top:2rem;
      width: 50vw;
    }

    .card-img {
      width: max-content;
      height: auto;
    }

    .background {
      width: 65vw;
      height: 92vh;
    }
}

@media (max-width:770px) {
    .image {
      margin-top:2rem;
      width: 100vh;
      margin-bottom: 5rem;
    }

    .card-img {
      width: max-content;
      height: auto;
      margin: 0;
    }

    .background {
      width: 75vw;
      height: 95vh;
    }
}

</style>
