<template>
  <div>
    <b-carousel-list
            v-model="values"
            :data="carousels"
            :arrow="true"
            :arrow-hover="true"
            :items-to-show="3"
            :items-to-list="1"
            :repeat="true"
            :has-drag="false" />
    <div>
        <h2 class="title">
            <p class="title-first-line">{{"Vous recherchez une photographe ?".toUpperCase()}}</p> <br>
            Vous êtes entré sur le bon site !<br><br>
            Je suis photographe basée sur la Côte d'Azur et Rhône-Alpes.<br>
            Mais je suis toujours prête à me déplacer où vous aurez besoin de moi !
        </h2>
    </div>
    <div class="has-text-centered links-container">
        
        <div class="columns">
            <div class="column is-half photos-links">
                <img  class="photo-links-ratio" :src="require('/src/assets/portrait/portrait (5).jpg')">
                <div class="formuleName has-text-centered" @click="redirect('/formules')">
                    <a class="portfolio-link textFormule" href="/formules">Voir mes <span class="styleName">Formules</span></a>
                </div>
            </div>

            <div class="column is-half photos-links">
                <img class="photo-links-ratio" :src="require('/src/assets/portrait/portrait (10).jpg')">
                <div class="formuleName has-text-centered" @click="redirect('/portfolio')">
                    <a class="portfolio-link textFormule" href="/portfolio">Voir le <span class="styleName">Portfolio</span></a>
                </div>
            </div>

        </div>
    </div>
    <div>
        <h2 class="title-avis">
            <p class="title-avis-text">Avis</p>
        </h2>
        <div class="columns is-multiline is-justify-content-center">
            <div class="column is-6" v-for="avis in all_avis" :key="avis.person">
                <div class="columns">
                    <div class="column is-half photo-avis">
                        <img style="max-height: 350px; border-radius:50%" :src="require('/src/assets/avis/'+avis.photo+'')">
                    </div>
                    <div class="column is-half">
                        <p class="p-5" style="text-align: justify;"><span style="font-size:2.5rem">❝</span>{{avis.text}}<span style="font-size:2.5rem; position:absolute">❞</span></p>
                        <span v-html="avis.person"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="is-flex is-justify-content-center mt-6">
        <a href="https://photopresta.fr" id="photopresta" class="mr-3"><img alt="Photographe vérifié sur PhotoPresta" src="https://d3p6b62xd0pwtt.cloudfront.net/img/badges/badge_photographe_verifie.png" height=128 width=128/></a>
        <a href="https://photopresta.fr/photographe-nice" id="photopresta"><img alt="Photographe à Nice sur PhotoPresta" src="https://d3p6b62xd0pwtt.cloudfront.net/img/badges/nice.png" height=128 width=128/></a>
    </div>
    <Footer></Footer>
  </div>

</template>

<script>
import Footer from './Footer.vue'

export default {
  name: 'New',
  components: { Footer },
  data() {
    return {
      carousels: [
          {
                    alt: 'Slide 1',
                    title: 'Slide 1',
                    image: require('/src/assets/carroussel/carroussel (2).jpg'),
                    srcFallback: require('/src/assets/carroussel/carroussel (2).jpg')
            },
          {
                    alt: 'Slide 2',
                    title: 'Slide 2',
                    image: require('/src/assets/carroussel/carroussel (3).jpg'),
                    srcFallback: require('/src/assets/carroussel/carroussel (3).jpg')
            },
          {
                    alt: 'Slide 3',
                    title: 'Slide 3',
                    image: require('/src/assets/carroussel/carroussel (4).jpg'),
                    srcFallback: require('/src/assets/carroussel/carroussel (4).jpg')
            },
          {
                    alt: 'Slide 4',
                    title: 'Slide 4',
                    image: require('/src/assets/carroussel/carroussel (5).jpg'),
                    srcFallback: require('/src/assets/carroussel/carroussel (5).jpg')
            },
          {
                    alt: 'Slide 5',
                    title: 'Slide 5',
                    image: require('/src/assets/carroussel/carroussel (6).jpg'),
                    srcFallback: require('/src/assets/carroussel/carroussel (6).jpg')
            },
          {
                    alt: 'Slide 6',
                    title: 'Slide 6',
                    image: require('/src/assets/carroussel/carroussel (7).jpg'),
                    srcFallback: require('/src/assets/carroussel/carroussel (7).jpg')
            },
          {
                    alt: 'Slide 7',
                    title: 'Slide 7',
                    image: require('/src/assets/carroussel/carroussel (8).jpg'),
                    srcFallback: require('/src/assets/carroussel/carroussel (8).jpg')
            },
          {
                    alt: 'Slide 8',
                    title: 'Slide 8',
                    image: require('/src/assets/carroussel/carroussel (9).jpg'),
                    srcFallback: require('/src/assets/carroussel/carroussel (9).jpg')
            },
            {
                    alt: 'Slide 9',
                    title: 'Slide 9',
                    image: require('/src/assets/carroussel/carroussel (1).jpg'),
                    srcFallback: require('/src/assets/carroussel/carroussel (1).jpg')
         },
      ],
      values: 1,
      all_avis: [
          {
              photo: 'Manon.jpg',
              text: "Le shooting avec Marylou c'est super bien passé ! Elle est douce et agréable, elle sait très vite détendre l'atmosphère. Ella a su me mettre à l'aise alors que je n'avais pas fais de shooting de ce genre depuis longtemps. Elle a aussi l'oeil pour les spots super sympas ! J'ai aussi été très satisfaite de son travail post-prod. Elle a su pousser l'univers qui se dégagait des photos brut. En bref : super pro et super cool ! Un parfait mélange !",
              person: 'Manon, <em>Dance Portrait</em>'
          },
          {
              photo: 'Diane_cheval.jpg',
              text: "Avec Marylou nous avons tenté un shooting avec kikidi un cheval n'ayant pas l'habitude d'être pris en photos ahah Nous avons sélectionné différentes tenue, brossé kikidi et nous nous sommes poser dans un champs avec le couché de soleil en fond. Ce fut une chouette expérience pour moi et kikidi nous avons pu tirer de magnifiques photos. Marylou m'a prêté une robe noir donnant une ambiance féerique au shooting... Elle a su être patiente avec un cheval qui pensait plus à manger que de faire le beau haha encore merci!",
              person: 'Diane, <em>Portrait avec animal</em>'
          },
          {
              photo: 'Maëlys.jpg',
              text: "Shooting très agréable, la photographe nous met à l'aise, toujours souriante. On a confiance en soi quand on est devant l’objectif. Elle est ouverte à toutes les idées et reste professionnelle tout en étant amicale et de bons conseils. Très bon shooting photo qui m’a bien amusé et le rendu des photos sont magnifiques.",
              person: 'Maëlys, <em>Portrait</em>'
          },
          {
              photo: 'Anastassia.jpg',
              text: "J'ai fait plusieurs shooting incroyables et je suis toujours autant satisfaite du résultat ! Toujours à l'écoute et dans le respect du modèle!",
              person: 'Anastassia, <em>Portrait mode</em>'
          },
          {
              photo: 'Lisa_Lisa.jpg',
              text: "Le shooting photo que nous avons réalisé avec Marylou était super. Une personne très agréable et compréhensive, avec un encadrement impeccable! Nous recommandons Marylou et son talent, nous n'hésiterons pas à faire appel à nouveau à ses services !",
              person: 'Lisa & Lisa, <em>Portrait duo soeur</em>'
          }
      ]
    }
  },
  methods: {
    redirect(redirect) {
        window.location.href = redirect
    }
  }
}
</script>

<style scoped>
#text {
  text-align: center;
}
#contain {
  padding: 2.5rem;
  min-height: 100vh;
}
.card-img {
  border-radius: 0.25rem;
}
.title {
    margin: 1rem;
    text-align: center;
    color: #F2FFFF;
    font-size: 1.5rem;
    font-family: 'Sofia Sans', sans-serif;
}
.title-first-line {
   font-size: 2rem;
   padding-bottom: 1rem;
   font-family: 'Moon Dance', cursive;
}

.title-avis {
    margin-top: 5rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 2rem;
}

.title-avis-text {
    color:#F2FFFF;
    font-family: 'Sofia Sans', sans-serif;
    border-top: #780E10 5px solid;
    border-bottom: #780E10 5px solid;
}

.photo-avis {
    display: flex;
    justify-content: center;
}

.portfolio-link {
    color:#F2FFFF;
    font-size: 2rem;
    transition: 0.3s;
}

.portfolio-link:hover {
    opacity: 0.5;
}

.formuleName {
    position: absolute;
    width: 400px;
    height: 600px;
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}
.textFormule {
    position: relative;
    top: 45%;
    font-size: 2rem;
    left:15px;
    font-family: 'Sofia Sans', sans-serif;
}

.formuleName:hover {
    opacity: 0;
}

.styleName {
    font-family: 'Moon Dance', cursive;
    position: relative;
    top: 15px;
    right: 15px;
    font-size: 2.75rem;
}

.links-container {
    margin: 5rem;
    height:800px
}

.photos-links {
    height:800px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:3rem
}

.photo-links-ratio {
    height: 800px;
}

@media (max-width:770px) AND (min-width: 550px) {
    .links-container {
        height: 1600px;
        margin: 3rem;
    }
    .photos-links {
        width: 99vw;
        height: 100vh;
    }
    .photo-links-ratio {
        height: 100vh;
    }
}

@media (max-width:550px) {
    .links-container {
        height: 1600px;
        margin: 3rem;
        margin-left: 1rem;
    }
    .photos-links {
        width: 99vw;
        height: 100vh;
    }
    .photo-links-ratio {
        height: auto;
    }

    .formuleName {
        width: 99vw;
        height: 100vh;
    }
}
</style>
