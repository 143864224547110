<template>
    <div>
        <div class="container has-text-centered mt-6">
            <span v-html="formulesText[formule].text1"></span>
            <br>
            <span v-html="formulesText[formule].text2"></span>
            <br>
            <span v-html="formulesText[formule].text3"></span>
            <div v-if="formule === 'MARIAGE'">
                <br>
                <div class="question"  @click="mQuestion = 1">{{'Déroulé'.toUpperCase()}}</div><br>
                    <div :class="mQuestion === 1 ? '': 'hidden'">
                        <div style="font-size:1.15rem; margin-top:1rem;font-weight: bold;">Avant le mariage</div><br>
                        Avant toutes choses, nous aurons un ou plusieurs rendez vous avant le grand jour que sera votre mariage afin de faire plus ample connaissance et discuter avec vous de l’organisation de cette journée unique pour ne louper aucuns moments forts! Plus tard, je m’occuperais de faire des repérages sur les différents lieux de la cérémonie si nécessaire afin d’être aux bons endroits et aux bons moments le jour J. Puis, nous échangerons par e-mail et téléphone tout au long de la préparation de votre mariage pour que vous soyez rassuré(e)s et sans pression. Je répondrais rapidement et le plus clairement possible à toutes vos questions <br>
                        <div style="font-size:1.15rem; margin-top:2rem;font-weight: bold;">Le jour J </div>
                        <br>Selon la formule et les options choisies pour votre mariage, je serais présent et même sûrement bien en avance afin d’être prêt à ne rien manquer de votre plus beau jour, car être photographe de mariage, c’est être prévoyant et organisé. Vous aurez donc le droit d’avoir ma bonne humeur, ma discrétion, mon professionnalisme, mon soutient (quelques mouchoirs si besoin aussi!) et de bons conseils afin de réaliser ces photos qui vous feront vous souvenir que ce jour était vraiment unique et que vous l’avez partagé avec la personne d’une vie et avec tous vos proches présents. <br>
                        <div style="font-size:1.15rem; margin-top:2rem;font-weight: bold;">Après le mariage</div><br>
                        Après le mariage, ce sera le moment pour vous de vous retrouver. Plus tard, débutera pour moi le travail de sélection des images de votre mariage et surtout leur post-production à laquelle j’apporterais un soin tout particulier. je m’engage à vous livrer mes photos dans un délai de 3 -4 semaines maximum. 
                        <br>
                        <br>
                    </div>
                <div class="question" @click="mQuestion = 2">CHAQUE FORMULE MARIAGE INCLUT</div>
                <div :class="mQuestion === 2 ? '': 'hidden'">
                    <div style="display: flex; justify-content: center; margin-top:1.5rem">
                        <ul style="list-style: inside; text-align: initial;">
                            <li>
                                <span style="font-weight: bold;">Échanges et conseils avant le mariage</span><br>
                                Physique / Téléphone / Email.  
                            </li>
                            <li> 
                                <span style="font-weight: bold;">Le reportage de votre mariage </span><br>
                                Jusqu'à 3 à 12h selon la formule .
                            </li>
                            <li>
                                <span style="font-weight: bold;">Post-production </span><br>
                                Vos photos, optimisées et retouchées.
                            </li> 
                            <li>
                                <span style="font-weight: bold;">Vos photos en haute définition </span><br>
                                Sans logo. 
                            </li>
                            <li>
                                <span style="font-weight: bold;">Galerie en ligne. </span><br>
                                Pour que vous et vos invités puissent télécharger vos photos & commander des tirages photos.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <p style="font-family: 'Sofia Sans', sans-serif;">
                <br>
                <span style="font-size:2rem" v-html="formulesText[formule].prix"></span>
                <span style="" v-html="formulesText[formule].time"></span>
                <br>
                <span style="" v-html="formulesText[formule].specifics"></span>
                <br>
            </p>
            <div v-if="formule==='MARIAGE'" style="display: flex;justify-content: space-around;align-items: center;">
                <img style="width:200px;transform: rotate(90deg);" :src="require('/src/assets/mariageCoffret.png')">
                <img style="width:300px;" :src="require('/src/assets/coffretMariage (1).jpg')">
                <img style="width:200px;" :src="require('/src/assets/coffretMariage (2).jpg')">
            </div>
            <div style="display:flex;margin:2rem;align-items: center;justify-content: center;">
                <div style="margin:5px;width:300px" v-for="image in formulesText[formule].photos" :key="image">
                    <img style="width: 400px;" :src="require('/src/assets'+image)">
                </div>
            </div>
            <a style="color:#F2FFFF" class="linkP" :href="formulesText[formule].redirect">{{formulesText[formule].redirectName || 'Portfolio'}} →</a>
        </div>
    </div>

</template>

<script>

export default {
  name: 'FormuleDetails',
  props: {
      formule: {
          type:String,
          required: false,
          default:""
      }
  },
  data() {
    return {
        mQuestion:0,
        formulesText: {
            "CRÉATION": {
                text1: `<div style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;">C’est quoi une séance portrait création?</div><br>`,
                text2: "Une séance parfaite pour vous, si vous souhaitez vous mettre à la place d'un modèle photo tout en vous amusant ! Optez pour un thème sur la mode type Vogue, un thème plus simpliste ou du boudoir.",
                text3: `<br>Que ce soit dans une forêt, en ville, à la mer, dans un musée ou en studio, plein d'endroits sont possibles pour réaliser de magnifiques photos pour des souvenirs inoubliables. Lieu(x) au choix, possibilité d'accessoires + prêt de tenue(s).
                        <br>
                        <br>
                        <a style="color:#F2FFFF; font-size:1.5rem; border: #780E10 3px solid; border-radius:0.25rem; padding:0.5rem" href="/info">Plus d'infos →</a>
                        `,
                prix: `<div style="border-top:#780E10 3px solid;">80€</div>`,
                time: "1h",
                specifics: `<div style="border-bottom:#780E10 3px solid; padding-bottom:1rem;">1/2 tenues<br>15/20 photos retouchées HD</div>`,
                photos: ['/portrait/portrait (52).jpg', '/portrait/portrait (55).jpg', '/portrait/portrait (29).jpg', '/portrait/portrait (57).jpg'],
                redirect:'/portfolio?cat=portrait'
            },
            "DUO": {
                text1: `<div style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;">C’est quoi une séance Duo?</div>`,
                text2: "Célébrez votre amour en réalisant une séance photo à deux dans une ambiance intimiste. Ou partagez un moment unique et fun entre ami(e)s. Comme on dit : plus on est de fou, plus on rit !",
                text3: `<br>A l’écoute des avis de mes clients, je suis à la recherche de sceller dans mes clichés des moments de bonheur partagé.
                        <br>
                        <br>
                        <a style="color:#F2FFFF; font-size:1.5rem; border: #780E10 3px solid; border-radius:0.25rem; padding:0.5rem" href="/info">Plus d'infos →</a>
                `,
                prix: `<div style="border-top:#780E10 3px solid;">140€</div>`,
                time: "1h30-2h",
                specifics: `<div style="border-bottom:#780E10 3px solid; padding-bottom:1rem;">20€ / personne en plus<br>20/25 photos retouchées HD</div`,
                photos: ['/portrait/portrait (34).jpg', '/portrait/portrait (54).jpg', '/portrait/portrait (3).jpg', '/portrait/portrait (46).jpg'],
                redirect:'/portfolio?cat=portrait'
            },
            "UNDERWATER": {
                text1: `<div style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;">C’est quoi une séance underwater?</div>`,
                text2: "Plongez en profondeur, je vous propose de vivre une expérience unique lors d’une séance photo sous-marine.",
                text3: `<br>Oubliez la gravité et venez dans les eaux transparentes de la Côte d’Azur ou même d'une piscine chauffée et privatisée. 
                        <br></br>
                        En fonction de vos envies et de votre apnée, beaucoup de choses sont possibles ! Seul ou à plusieurs, tentez l'experience ! 
                        <br><br>
                        Peu importe la tenue, le résultat est féérique !
                        <br><br>
                        <a style="color:#F2FFFF; font-size:1.5rem; border: #780E10 3px solid; border-radius:0.25rem; padding:0.5rem" href="/info">Plus d'infos →</a>
                `,
                prix: `<div style="border-top:#780E10 3px solid;">130€</div>`,
                time: "2h",
                specifics: `<div style="border-bottom:#780E10 3px solid; padding-bottom:1rem;">2 tenues - prêt/location de tenues et d'accessoires sur demand<br>20/25 photos retouchées HD</div>`,
                photos: ['/portrait/portrait (42).jpg', '/portrait/portrait (40).jpg', '/portrait/portrait (4).jpg', '/portrait/portrait (36).jpg'],
                redirect:'/portfolio?cat=portrait'
            },
            "MARIAGE":{
                text1: `<div style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;">Racontez votre histoire et créez vos souvenirs !`,
                text2: "Tout d’abord mes félicitations, si vous lisez ce texte c’est que j’ai réussi à toucher votre intérêt et j’en suis ravie. <br><br>Un moment clé dans la vie d’un couple et d’une famille, il est important de sélectionner son photographe avec soin, pour avoir de ce jour un souvenir inoubliable.",
                text3: ``,
                prix: "",
                time: `<div>
                        <p style="font-size:1.5rem; border:#F2FFFF 1px solid; padding:1rem">Instant Magique</p><br>
                        <div style="font-size:1.5rem">830€</div> 
                        <span style="font-size:1.25rem">cérémonie + couple</span>
                        <br>
                        <span style="font-size:1.25rem">3h</span>
                        <br>
                        <br>
                        <p style="font-size:1.5rem; border:#F2FFFF 1px solid; padding:1rem">Coeur en Fête</p><br>
                        <div style="font-size:1.5rem">1080€</div> 
                        <span style="font-size:1.25rem">cérémonie + couple + groupe + vin d'honneur</span>
                        <br>
                        <span style="font-size:1.25rem">5h</span>
                        <br>
                        <br>
                        <p style="font-size:1.5rem; border:#F2FFFF 1px solid; padding:1rem">L'amour en Grand</p><br>
                        <div style="font-size:1.5rem">1560€</div>
                        <span style="font-size:1.25rem">préparatif + cérémonie + couple + groupe + vin d'honneur + soirée </span>
                        <br> 
                        <span style="font-size:1.25rem">10h</span>
                        <br>
                        </div>
                `,
                specifics: `<br><br>Options en plus : tirages photos, livre photos,le coffret mariage, etc… <br><span style="font-size:1.5rem">Demandez un devis</span>`,
                photos: ['/mariage/mariage (16).jpg', '/mariage/mariage (17).jpg', '/mariage/mariage-formule.jpg', '/mariage/mariage (36).jpg'],
                redirect:'/portfolio?cat=mariage'
            },
            "EVJF": {
                text1: `<div style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;">C’est quoi une séance Enterrement de vie de jeune fille - EVJF ?`,
                text2: "Nous réaliserons de belles photos de portraits, de groupe mais aussi de beaux duos avec la future mariée et chacune des participantes.",
                text3: `<br>Je propose des shootings photo EVJF personnalisés et glamours. Faite passer un très bon moment dont la future mariée se souviendra toute sa vie ! 
                        <br><br>L'objectif : vous offrir la plus belle activité et de magnifiques photos de votre enterrement de vie de jeune fille. `,
                prix: "",
                time: `<div style="border-top:#780E10 3px solid;border-bottom:#780E10 3px solid;">
                        <span style="font-size:1.5rem">Basic evjf</span><br>
                        <div style="font-size:1.25rem">225€</div> 1h
                        <br>
                        <br>
                        <span style="font-size:1.5rem;font-weight:500">Super evjf</span><br> 
                        <div style="font-size:1.25rem">275€ </div> 1h30 
                        <br>
                        <br>
                        <span style="font-size:1.5rem; font-weight:500">Best evjf</span><br>
                        <div style="font-size:1.25rem">325€ </div> 2h30 
                        </div>
                `,
                specifics: "",
                photos: ['/mariage/mariage (8).jpg', '/mariage/mariage (7).jpg', '/mariage/mariage (11).jpg'],
                redirect:'/portfolio?cat=mariage'
            },
            "IMMOBILIER": {
                text1: `<div style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;">C’est quoi une séance immobilier?</div>`,
                text2: "Je propose cette formule de photographie aux entreprises tout comme aux particuliers (entreprises, magasins, restaurant, Airbnb, Hôtels, piscine/spa etc.…)<br><br> Montrez vos biens sous leur meilleur jour avec de superbes photos.",
                text3: `<ul style="margin-top:2rem; padding-bottom:2rem; padding-top:1rem;border-top:#780E10 3px solid;border-bottom:#780E10 3px solid;">
                        <li>
                        <span style="font-size:1.15rem; font-weight:500">Studio</span>
                        <br>90€</li>
                        <br>
                        <li>
                        <span style="font-size:1.15rem; font-weight:500">Appartement</span>
                        <br>110€</li>
                        <br>
                        <li>
                        <span style="font-size:1.15rem; font-weight:500">Maison</span>
                        <br>135€ </li>
                        <br>
                        <li>
                        <span style="font-size:1.15rem; font-weight:500">Hôtel, villa etc...</span>
                        <br>sur devis </li>
                        </ul>
                    `,
                prix: "",
                time: "",
                specifics: "",
                photos: ['/immobilier/immobilier (4).jpg', '/immobilier/immobilier (13).jpg', '/immobilier/immobilier (11).jpg', '/immobilier/immobilier (17).jpg'],
                redirect:'/portfolio?cat=immobilier'
            },
            "BOOK MODEL": {
                text1: `<div style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;">Vous souhaitez vous lancer dans le mannequinat ?</div>`,
                text2: ` Vous avez envie de relever un nouveau défi ? Il vous faut donc un portfolio ! En studio ou en extérieur, je saurais mettre en avant votre potentiel. <br><br>
                <ul style="margin-top:2rem; padding-bottom:2rem; padding-top:1rem;border-top:#780E10 3px solid;border-bottom:#780E10 3px solid;">
                        <li>
                            <span style="font-size:1.5rem; font-weight:500">Polas</span><br>
                            <span style="font-size:1.35rem;"> 45€</span>
                        </li>
                        <br>
                        <li>
                            <span style="font-size:1.5rem; font-weight:500">Book simple</span> <br>
                            <span style="font-size:1.35rem;">60€</span> <br>
                            50 min / 2 Tenues <br>
                            10/15 photos retouchées hd
                        </li> 
                        <br>
                        <li>
                           <span style="font-size:1.5rem; font-weight:500">Book avancé</span> <br>
                            <span style="font-size:1.35rem;">110€</span> <br>
                            3 H / 4 tenues <br>
                            Plusieurs lieux <br>
                            40/45 photos retouchées hd
                        </li>
                        </ul>
                        `,
                text3: "",
                prix: "",
                time: "",
                specifics: "",
                photos: ['/portrait/bookModel (1).jpg', '/portrait/bookModel (2).jpg', '/portrait/bookModel (3).jpg', '/portrait/bookModel (4).jpg'],
                redirect:'/portfolio?cat=portrait'
            },
            "REPORTAGE ENTREPRISE": {
                text1: `<div style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;">Soignez votre image `,
                text2: `Vous êtes un professionnel et vous voudriez du contenu sur votre site internet ou sur les réseaux sociaux ?<br> Je vous aiderai à montrer la meilleure image de vous-même et de vos produits.`,
                text3: `<ul style="margin-top:2rem; padding-bottom:2rem; padding-top:1rem;border-top:#780E10 3px solid;border-bottom:#780E10 3px solid;">
                        <li>
                            <span style="font-size:1.15rem; font-weight:500">Photos pro CV</span><br>
                            (cv, présentation site internet etc...) <br>
                            <span style="font-size:1.25rem">45€</span>
                        </li>
                        <br>
                        <li>
                            <span style="font-size:1.15rem; font-weight:500">Packshot produit</span> <br>
                            <span style="font-size:1.25rem">10€/image</span>
                        </li>
                        <br> 
                        <li>
                            <span style="font-size:1.15rem; font-weight:500">Reportage photo</span> <br>
                            <span style="font-size:1.25rem">90 € / 1h </span><br>
                            <span style="font-size:1.25rem">190€ / 3h </span><br>
                            Inclus : photos portrait, locaux, du travail en action, produits... + de 70 photos retouchées hd
                        </li>
                        </ul>
                        <br>
                        <span style="font-size:1.5rem; font-weight:500">Options</span>
                        <br><br>
                        <ul>
                        <li>
                            <span style="font-size:1.15rem; font-weight:500">Make-up</span><br> 
                            Il est possible de prendre l’option Hair & Make up par un professionnel, pour mise en beauté simple, un make up travaillé, artistique, coiffure, etc... 
                            le prix sera celui du prestataire 
                        </li>
                        <br>
                        <br>
                        <li>
                            <span style="font-size:1.15rem; font-weight:500">Retouches de photos</span><br>
                            Si vous avez des photos et que vous souhaitez les embellir grâce à un œil artistique de retouche d'un photographe, c'est possible ! <br>
                            
                            <br>
                             <li>
                                <span style="font-size:1.15rem; font-weight:500">Photo simple</span> <br>
                                10 € /unité 
                            </li>
                            <li>
                                    <span style="font-size:1.15rem; font-weight:500">Photo travaillée</span><br>
                                    15€ /unité
                            </li>
                        </li>
                        </ul>
                        `,
                prix: "",
                time: "",
                specifics: "",
                photos: ['/formules/REPORTAGE ENTREPRISE.png', '/professionnels/professionnels (19).jpg', '/professionnels/entrepriseFormule.jpg', '/professionnels/professionnels (2).jpg'],
                redirect:'/portfolio?cat=portrait'
            },
            "BON CADEAUX": {
                text1: `<span style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;">Une carte cadeau pour des photos inoubliables.</span> <br><br>
                        Vous ne savez pas quoi offrir à une amie pour son anniversaire ? Vous désirez graver votre
                        relation en passant un bon moment ? Vous cherchez un cadeau de Noël exceptionnel, qui
                        change de l'ordinaire ? Il n'y a QUE de bonnes occasions pour offrir une séance photo. C'est
                        une expérience unique qui permet d'immortaliser un instant de vie. Que ce soit seul, en duo,
                        entre amis ou bien en famille, vous ferez à coup sûr un grand plaisir en offrant ce cadeau.
                `,
                text2: `<p style="font-size:1.5rem;font-family: 'Sofia Sans', sans-serif;margin-top:3rem; margin-bottom:-2rem">À propos :</p>
                <br>
                ............................................................................................................
                <br>
                <ul style="list-style: inside;">
                <li>
                    Les bons cadeaux sont valables un an à partir de la date d'achat. En studio, à domicile ou bien en extérieur à la lumière naturelle.
                </li>
                <li>
                Le principe est simple, vous choisissez la formule et le type de séance que vous souhaitez offrir (portrait solo, duo/couple...)
                </li>
                <li>
                Vous m'indiquez le nom de la personne que vous voulez gâter, un petit mot à écrire si vous le souhaitez et je m'occupe de vous envoyer la carte cadeau pour que vous puissiez la lui remettre en main propre ou en version numérique, selon votre préférence.
                </li>
                <li>
                L'intégralité de mes prestations sont totalement personnalisables et peuvent être proposées sous forme de Bons Cadeaux.
                </li>
                </ul>
                <br><br>
                <p style="font-size:1.25rem;">Alors, n'attendez plus et surprenez-les !</p>`,
                text3: `Si vous voulez plus d'informations <span style="border:#780E10 1px solid; background-color:#780E10;border-radius:0.25rem">Contactez-moi</span>`,
                prix: "",
                time: "",
                specifics: "",
                photos: ['/carteCadeaux/carteCadeaux.png', '/carteCadeaux/carteCadeaux1.jpg', '/carteCadeaux/carteCadeaux2.jpg'],
                redirect:'/formules',
                redirectName: "Formules"
            }
        }
    }
  },
  created() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
  }
}
</script>

<style scoped>
#text {
  text-align: center;
}
#contain {
  padding: 2.5rem;
}
.card-img {
  border-radius: 0.25rem;
}
.container {
    min-height: 100vh;
}
.column-formules {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.question {
  font-family: 'Sofia Sans';
  font-size: 1.5rem;
  border-top: #780E10 3px solid;
  border-bottom: #780E10 3px solid;
}

.title {
    margin: 1rem;
    text-align: center;
    font-size: 2rem;
}

.title::first-letter {
    color: #780E10;
}

#titreF{
    padding: 5px;
    background-color: #F2FFFF;
    border-radius: 20px;
}

.linkP{
    font-family: 'Sofia Sans';
    transition: 0.3s;
    border:#780E10 2px solid;
    border-radius: 0.25rem;
    padding: 2px;
    font-size: 2rem;
}

.linkP:hover {
    opacity: 0.5;
}

.hidden {
    display: none;
}

@media (max-width:770px) {
    .column-image {
        min-width: fit-content;
    }
}
</style>