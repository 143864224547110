<template>
    <div id="footer">
        <div style="height:3px; width:100vw;background-color:#780E10; margin-bottom:0.5rem"></div>
        <p id="footer-name">Marylou JIMENEZ       -       MIDS Photography</p>
        <br/>
        <br/>
        <a :href="mentionLegales" download="mention légales.pdf" class="link-underlined">Mention Légales</a> - <a :href="cgv" download="cgv.pdf" class="link-underlined">CGV</a>
        <br/>
        <br/>
        Contact
        <br/>
        <p style="margin-top:5px; margin-bottom: -20px;">contact.mids@gmail.com </p>
        <br/>
        06 86 49 36 75
        <br/>
        <a href="https://www.instagram.com/mj_mids/">
        <img :src="require('/src/assets/icons/instagram.svg')" style="height:25px; margin-top:5px">
        </a> - 
        <a href="https://www.facebook.com/MIDSphotography">
        <img :src="require('/src/assets/icons/facebook.svg')" style="height:25px;">
        </a>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
      return {
        cgv: require(`${process.env.BASE_URL}src/assets/CGV MIDS Photogrpahy.pdf`),
        mentionLegales: require(`${process.env.BASE_URL}src/assets/mentions légales MIDS Photography.pdf`),
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#footer {
    text-align: center;
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #161C1C;
    padding: 1rem;
}

a {
    color: #F2FFFF;
}

#footer-name{
    font-family: 'Sofia Sans', sans-serif;
}

.link-underlined {
    text-decoration: underline;
}
</style>