<template>
  <div>
    <h2 class="has-text-centered big-title">
      Portfolio
    </h2>
    <div class="buttonGoTo">
    <div v-for="titre in Object.keys(nbPhoto)" :key="titre"  class="buttonP">
      <div @click="goTo(titre)">{{titre}}</div>
    </div>
  </div>
    <div class="content" id="contain">
        <div v-for="titre in Object.keys(nbPhoto)" :key="titre" :id="titre">
          <p class="title">{{titre}}</p>
          <div class="column-photo">
            <div v-for="image in nbPhoto[titre]" :key="image">
              <img :src="require('/src/assets/'+titre+'/'+titre+' ('+image+').jpg')" style="padding:5px;">
            </div>
          </div>
        </div>
    </div>
    <Footer></Footer>
  </div>

</template>

<script>
import Footer from './Footer.vue'

export default {
  name: 'Portfolio',
  components: { Footer },
  data() {
    return {
      nbPhoto:{
        "portrait": 67,
        "mariage": 51,
        "professionnels": 19,
        "immobilier": 22,
      },
    }
  },
  methods: {
    goTo(title) {
      document.getElementById(title).scrollIntoView({
        behavior:'smooth'
    })
    }
  }
}
</script>

<style scoped>
#text {
  text-align: center;
}
#contain {
  padding: 2.5rem;
  min-height: 100vh;
}

.title {
    margin: 1rem;
    text-align: center;
    color: #F2FFFF;
    font-size: 2rem;
    border-top: #780E10 5px solid;
    border-bottom: #780E10 5px solid;
}

.title::first-letter {
  text-transform:capitalize;
}

.column-photo{
  column-count: 4;
}

.big-title {
  font-size: 5rem;
  font-family: 'Moon Dance', cursive;
  margin-top: 2rem;
}

.buttonGoTo {
    position: sticky;
    top: 100px;
    background-color: #161c1c;
    display: flex;
    justify-content: space-around; 
    margin-left:2rem;
    flex-wrap: wrap;
}
.buttonP {
  font-size: 1.5rem;
  font-family: 'Sofia Sans', sans-serif;
  border-right: #780E10 2px solid;
  padding-right:5vw;
}

.buttonP:last-of-type {
  border: none;
}

.buttonP::first-letter {
  text-transform:capitalize;
}

@media (max-width:770px) {
    .column-photo{
      column-count: 2;
    }
}

@media (max-width:500px) {
    .column-photo{
      column-count: 1;
    }
}
</style>
