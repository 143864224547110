<template>
    <div>
        <div class="container">
                <div v-for="titre in Object.keys(formules)" :key="titre" :id="titre">
                    <p class="catTitle"><span class="titleBorder">{{titre}}</span></p>
                    <div class="columns is-multiline column-formules">
                        <div class="column is-narrow column-image" style="margin:5px; height:650px" v-for="image in formules[titre]" :key="image" @click="detailsFormule(image)">
                            <img class="image-F" :src="require('/src/assets/formules/'+image+'.png')">
                            <div class="formuleName has-text-centered">
                                <p class="textFormule">{{titre}} <span class="styleName">{{image}}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>

</template>

<script>

export default {
  name: 'FormuleComonent',
  props:{
      cat:{
          type:String,
          required: false,
          default:""
      }
  },
  data() {
    return {
        formules: {
            "Portrait": ["CRÉATION","DUO","UNDERWATER"],
            "Mariage": ["MARIAGE","EVJF"],
            "Corporate": ["IMMOBILIER","BOOK MODEL", "REPORTAGE ENTREPRISE"]
        }
    }
  },
  mounted() {
    document.getElementById(this.cat).scrollIntoView({
        behavior:'smooth'
    })
  },
  methods: {
      detailsFormule(formule) {
          this.$emit('detailsFormule', formule)
      }
  }
}
</script>

<style scoped>
.container {
    min-height: 100vh;
}
.column-formules {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.catTitle {
    margin: 1rem;
    margin-top: 3rem;
    text-align: center;
    color: #F2FFFF;
    font-size: 2rem;
}

.titleBorder {
    border-top: #780E10 2px solid;
    border-bottom: #780E10 2px solid;
}

.title {
    margin: 1rem;
    text-align: center;
    color: #F2FFFF;
    font-size: 2rem;
}

.title::first-letter {
    color: #780E10;
}

.formuleName {
    position: relative;
    top: -605.5px;
    width: 400px;
    height: 600px;
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}
.textFormule {
    position: relative;
    top: 45%;
    font-size: 2rem;
    left:15px;
}

.styleName {
    font-family: 'Moon Dance', cursive;
    position: relative;
    top: 15px;
    right: 25px;
}

.formuleName:hover {
    opacity: 0;
}

.image-F {
    width: 400px;
}

@media (max-width:770px) {
    .column-image {
        min-width: fit-content;
    }
    .column-formules {
        margin-left: 20vh;
    }
}

@media (max-width:575px) {
    .column-formules {
        margin-left: 1rem;
    }

    .image-F {
        width: 50vh;
        height: auto;
        margin-bottom: 2rem;
    }
    .formuleName {
        width: 50vh;
        height: 77vh;
    }

    .column-image {
        display: grid;
        justify-content: center;
    }
}
</style>