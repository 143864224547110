<template>
  <div>
    <div class="head">
      <div id="img-contain">
      <a href="/">
      <img :src="require('/src/assets/logoMFinal.jpg')" class="logo">
      </a>
      </div>
    </div>
    <b-navbar centered>
        <template #start>
          <b-navbar-item href="/">
                <p class="nav-bar-item-border"><span class="item-navbar">A</span>ccueil</p>
            </b-navbar-item>
            <b-navbar-item href="/who">
                <p class="nav-bar-item-border"><span class="item-navbar">Q</span>ui suis-je ?</p>
            </b-navbar-item>
            <b-navbar-item href="/formules">
                <p class="nav-bar-item-border"><span class="item-navbar">F</span>ormules</p>
            </b-navbar-item >
            <b-navbar-item href="/info">
                <p class="nav-bar-item-border"><span class="item-navbar">I</span>nfos</p>
            </b-navbar-item >
            <b-navbar-item href="/portfolio">
                <p class="nav-bar-item-border-last"><span class="item-navbar">P</span>ortfolio</p>
            </b-navbar-item >
        </template>
    </b-navbar>
    <component :is="curentView"></component>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import New from './components/newCom.vue'
import Formules from './components/Formules.vue'
import Accueil from './components/Accueil.vue'
import Portfolio from './components/portfolio.vue'
import Info from './components/Info.vue'

const routes = {
  '/': Accueil,
  'contact': HelloWorld,
  'formules': Formules,
  'who': New,
  'portfolio': Portfolio,
  'info': Info
}

export default {
  name: 'App',
  metaInfo: {
      title: 'Mids Photography',
  },
  data() {
    return {
      currentPath: window.location.href,
    }
  },
  computed: {
    curentView() {
      let path = this.currentPath.split('/')[ this.currentPath.split('/').length - 1]
      if (this.currentPath.includes('?')){
        path = path.split('?')[0]
      }
      return routes[path || '/']
    },
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.href
    })
  },
  created() {
    window.title = "mids-photography"
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Moon+Dance&family=Noto+Serif+Toto&family=Sofia+Sans&display=swap');
</style>

<style>

body {
  background-color: #161c1c;
  color: #F2FFFF;
  overflow: hidden;
}
.head {
  background-color: #161C1C;
  position: sticky;
  overflow: hidden;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
}
.logo {
  height: 10rem;
}
#img-contain {
  height: 6rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.item-navbar {
  color:#780E10;
  font-size: 1.5rem;
}
.nav-bar-item-border {
  font-family: 'Sofia Sans', sans-serif;
  border-right: black 1px solid;
  padding-right: 20px;
}
.nav-bar-item-border-last {
  font-family: 'Sofia Sans', sans-serif;
}
</style>