<template>
  <div>
    <h2 class="has-text-centered big-title">
      Infos
    </h2>
    <div class="hello">
      <div>
        Je ne diffuse pas les photos sans votre autorisation préalable. <br><br>
        De votre côté vous pouvez bien entendu diffuser les photos, il faut simplement bien penser à m’identifier/indiquer mon nom. 
        <br>
        C’est obligatoire dans la loi, et cela me permet d’avoir de la visibilité, et donc des clients.
        <br>
        <br>
        Enfin, il est interdit d’ajouter des filtres ou des retouches par-dessus mes photos. 
        <br>
        <br>
        <br>
        <br>
        <b @click="questionOpen = 2" class="question" >{{'Je veux réserver une séance'.toUpperCase()}} </b>
        <br>
        <br>
        <div :style="questionOpen!=2 ? 'display:none': ''">
          Vous pouvez me contacter sur Instagram, par message ou par mail pour réserver une séance.
        </div>
        <br><br>
        <b @click="questionOpen = 7" class="question">{{'Je n’ai jamais posé ?'.toUpperCase()}} </b>
        <br><br>
        <div :style="questionOpen!=7 ? 'display:none': ''">90% de mes clientes n’avaient jamais posé avant. C’est tout à fait normal de ne pas être à l’aise au début. C’est mon rôle de savoir te mettre à l’aise et te guider.<br> Pas de panique ! On prend notre temps. Je montre les photos durant toute la séance pour vous rassurer.</div>
        <br>
        <br>
        <b @click="questionOpen = 4" class="question">{{"À qui s'adressent les séances photo ?".toUpperCase()}}</b>
        <br>
        <br>
        <div :style="questionOpen!=4 ? 'display:none': ''">
        À TOUS ! Débutants comme professionnels. Ça peut aller de la photo simple, naturelle à la plus sophistiquée et artistique.
        <br>Que ce soit pour vous redonner confiance en vous et votre corps, pour un book, juste pour le plaisir, créer du contenu pour vos réseaux sociaux ou mettre en valeur votre marque. 
        <br><br>Toutes les raisons sont bonnes pour avoir de belles photos.
        <br><br>
        Les personnes qui ne sont pas habituées à être prises en photo PAS DE PANIQUE!!! Il n'y a pas de crainte à avoir. 
        <br>Je prendrais le temps nécessaire pour vous mettre à l'aise et je vous guiderai et conseillerais si vous n'avez pas d'idées de pose, etc. 
        <br><br>Mon but est de vous faire passer un bon moment et que vous soyez satisfait du résultat ! Il n'y a pas de jugement avec moi, le respect, la positivité et l'ouverture d'esprit est INDISPENSABLE ! *
        </div>
        <br>
        <br>
        <b @click="questionOpen = 3" class="question">{{'Comment se déroule une séance photo ?'.toUpperCase()}}</b>
        <br>
        <br>
        <div :style="questionOpen!=3 ? 'display:none': ''">
        Nous commençons par une prise de contact DM/Email... Nous discutons de votre projet, attentes... Puis nous construisons un moodboard ensemble (une planche d'inspiration afin de pouvoir mieux visualiser ce que l'on a en tête).
        <br>
        <br>
        Selon le projet, il faudra peut-être rassembler une équipe (maquillage, coiffure, stylisme). 
        <br>Rechercher des tenues, accessoires, décos... Ensuite, nous synchronisons nos plannings pour trouver une date. Avant le shooting, je fais des repérages de lieux pour trouver l'emplacement parfait pour la prestation. 
        <br><br>À la session de shooting, laissez place à votre moment, votre personnalité ! 
        <br>
        <br>Je trierais ensuite les photos et vous enverrais une planche contact numérique, de là, vous devriez choisir un certain nombre de photos selon la formule prise.
        <br>
        Ensuite, la magie opérera quand je retoucherais les clichés. Dans un délai maximum de 3 semaines, je vous enverrai un lien drive où toutes les photos s'y trouveront et où vous pourrez les télécharger en totale qualité HD.</div>
        <br>
        <br>
        <b @click="questionOpen = 1" class="question">LES FORMULES DE MES SHOOTINGS PHOTOS COMPRENNENT</b>
          <br>
          <br>
          <div :style="questionOpen!=1 ? 'display:none': ''">
            <b>LE TEMPS DE MISE EN PLACE.</b>
            <br>
            
            <div>
              Que ce soit en studio ou à l’extérieur, nous prendrons un peu de temps pour discuter de votre projet, mais aussi de vous.
              <br>
              Le but étant de détendre un peu l’atmosphère et de mieux cerner votre personnalité pour un meilleur résultat.
              <br>
              Nous verrons ensemble les différentes façons de poser par la suite.
            </div>
            <br>
            <br>

            <b>L’UTILISATION DU MATÉRIEL PHOTOGRAPHIQUE.</b>
            <br>
            <div>
            Comme tout photographe professionnel, je possède un studio photo avec un très bon matériel, je suis équipé d’un boîtier Canon .
            <br>
            J’utilise une gamme complète d’objectif allant de 14mm jusqu’à 200mm.
            </div>
            <br>
            <br>
      
            <b>LE TEMPS DE PRISE DE VUE.</b>
            <br>
            <div>
            Suivant la formule choisi, nous passerons 1 à 3 heures ensemble pour réaliser une séance de portrait.
            <br>
            Le changement de tenue, de maquillage ou de coiffure sont compris dans le temps du shooting. Pensez à vous organiser.
            </div>
            <br>
            <br>

            <b>LIVRAISON DES PHOTOS HD</b>
            <br>
            <div>
            Les photographies sont livrées en numériques (jpeg) en Haute définition pour une qualité optimale à l’impression
            <br>
            et/ou en Basse définition pour une diffusion sur votre site web et les réseaux sociaux.
            </div>
            <br>
            <br>

            <b>LE POST-TRAITEMENT DES IMAGES.</b>
            <br>
            <div>
            Toutes les photographies que nous réaliserons ensemble seront présentées sur une planche contact.
            <br>
            De là, vous sélectionnerez les photos que je retoucherais de manière approfondie.
            </div>
            <br>
            <br>

            <b>LES DROITS DE DIFFUSION ET DE REPRODUCTION.</b>
            <br>
            <div>
            Toutes les photographies que vous avez achetées vous appartiennent.
            <br>
            Vous pouvez donc les utiliser pour les diffuser ou bon vous semble, que ce soit sur Internet, sur votre cv ou votre book.
            <br>
            Vous pouvez aussi les reproduire en tirage photo, poster, etc…
            </div>
          </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './Footer.vue'

export default {
  name: 'Info',
  components: { Footer },
  data() {
    return {
      questionOpen:0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  padding-top: 2.5rem;
  padding-right: 2rem;
  padding-left: 1.5rem;
  text-align: center;
  min-height: 60vh;
}

.question {
  font-family: 'Sofia Sans';
  font-size: 1.5rem;
  border-top: #780E10 5px solid;
  border-bottom: #780E10 5px solid;
}

.big-title {
  font-size: 5rem;
  font-family: 'Moon Dance', cursive;
  margin-top: 2rem;
}
</style>
