<template>
    <div>
        <div class="container">
            <div class="mt-3">
            <a v-if="chosenFormule!=''" href="/formules" class="return">← Formules</a>
            </div>
            <h2 class="has-text-centered big-title">
                {{chosenFormule != ""? chosenFormule :"Formules"}}
            </h2>
            <component :is="component" @change="changeComponent" :cat="chosenCat" @detailsFormule="changeFormule" :formule="chosenFormule"></component>
        </div>
        <Footer></Footer>
    </div>

</template>

<script>
import Footer from './Footer.vue'
import FormuleButton from './Formules/FormuleButton.vue'
import FormuleComonent from './Formules/FormuleComonent.vue'
import FormuleDetails from './Formules/FormuleDetails.vue'

export default {
  name: 'Formules',
  components: { Footer },
  data() {
    return {
        formules: 11,
        component: FormuleButton,
        chosenCat: "",
        chosenFormule: ""
    }
  },
  methods: {
      changeComponent(cat) {
          this.chosenCat = cat
          this.component = FormuleComonent
      },
      changeFormule(formule) {
          this.chosenFormule = formule
          this.component = FormuleDetails
      }
  }
}
</script>

<style scoped>
#text {
  text-align: center;
}
#contain {
  padding: 2.5rem;
}

.big-title {
  font-size: 5rem;
  font-family: 'Moon Dance', cursive;
  margin-top: 2rem;
  border-top: #780E10 solid 5px;
   border-bottom: #780E10 solid 5px;
}

.card-img {
  border-radius: 0.25rem;
}
.container {
    min-height: 100vh;
}
.column-formules {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.title {
    margin: 1rem;
    text-align: center;
    font-size: 2rem;
}

.title::first-letter {
    color: #780E10;
}

#titreF{
    padding: 5px;
    background-color: #F2FFFF;
    border-radius: 20px;
}

.return{
    font-family: 'Sofia Sans';
    transition: 0.3s;
    font-size: 1.5rem;
    color: #F2FFFF;
}

.return:hover{
    opacity: 0.5;
}

@media (max-width:770px) {
    .column-image {
        min-width: fit-content;
    }
}
</style>